export const WORDS = [
  'tidak',
  'untuk',
  'orang',
  'harus',
  'pergi',
  'dalam',
  'hanya',
  'ingin',
  'semua',
  'semut',
  'basuh',
  'teruk',
  'sudah',
  'punya',
  'lebih',
  'benar',
  'bukan',
  'telah',
  'siapa',
  'dapat',
  'kasih',
  'bagus',
  'malam',
  'lihat',
  'waktu',
  'tahun',
  'masih',
  'pikir',
  'hidup',
  'kalau',
  'tuhan',
  'salah',
  'perlu',
  'rumah',
  'cepat',
  'masuk',
  'jalan',
  'tentu',
  'boleh',
  'cukup',
  'kecil',
  'makan',
  'belum',
  'teman',
  'besar',
  'dunia',
  'pasti',
  'mulai',
  'tetap',
  'gadis',
  'yakin',
  'ingat',
  'tidur',
  'buruk',
  'tanpa',
  'bawah',
  'terus',
  'cinta',
  'mobil',
  'takut',
  'depan',
  'sakit',
  'suatu',
  'ambil',
  'butuh',
  'hebat',
  'tepat',
  'bodoh',
  'biasa',
  'duduk',
  'minta',
  'bulan',
  'pintu',
  'nanti',
  'jatuh',
  'besok',
  'minum',
  'turun',
  'indah',
  'dekat',
  'mudah',
  'jelas',
  'sejak',
  'keras',
  'sulit',
  'kapan',
  'kawan',
  'kedua',
  'suara',
  'ruang',
  'kamar',
  'kapal',
  'akhir',
  'tanah',
  'bunuh',
  'panas',
  'penuh',
  'kerja',
  'empat',
  'kabar',
  'darah',
  'mesin',
  'marah',
  'nyata',
  'harap',
  'kanan',
  'jenis',
  'tutup',
  'tahan',
  'dadah',
  'tanda',
  'jumpa',
  'janji',
  'surat',
  'lewat',
  'namun',
  'mimpi',
  'kelas',
  'pesan',
  'kenal',
  'bantu',
  'merah',
  'encik',
  'benda',
  'benci',
  'manis',
  'kunci',
  'jahat',
  'paman',
  'putih',
  'tanya',
  'siang',
  'macam',
  'hukum',
  'istri',
  'berat',
  'wajah',
  'musik',
  'betul',
  'jawab',
  'bebas',
  'hitam',
  'mandi',
  'putri',
  'pohon',
  'bunga',
  'musuh',
  'bukti',
  'pakai',
  'utama',
  'utara',
  'asing',
  'sibuk',
  'hutan',
  'tujuh',
  'balik',
  'titik',
  'gagal',
  'pusat',
  'mampu',
  'lapar',
  'mulut',
  'kulit',
  'kotak',
  'pukul',
  'milik',
  'lahir',
  'angin',
  'nenek',
  'tarik',
  'ikuti',
  'gelap',
  'garis',
  'bahan',
  'puluh',
  'kalah',
  'mabuk',
  'lampu',
  'hujan',
  'hewan',
  'kakak',
  'suami',
  'hantu',
  'sadar',
  'kotor',
  'timur',
  'lelah',
  'mohon',
  'barat',
  'buang',
  'paham',
  'tiada',
  'warna',
  'lepas',
  'pulau',
  'teori',
  'kisah',
  'putus',
  'sobat',
  'entah',
  'habis',
  'sedih',
  'tikus',
  'kacau',
  'pisau',
  'hijau',
  'kursi',
  'layak',
  'kirim',
  'taman',
  'budak',
  'harga',
  'badai',
  'jalur',
  'telur',
  'sihat',
  'pihak',
  'tiket',
  'sebut',
  'sebab',
  'sudut',
  'kabur',
  'hasil',
  'seksi',
  'meter',
  'jarak',
  'ganti',
  'fikir',
  'biaya',
  'kasar',
  'kisah',
  'bakar',
  'suruh',
  'belas',
  'setan',
  'mesti',
  'palsu',
  'bayar',
  'taksi',
  'salam',
  'cakap',
  'pekan',
  'tewas',
  'nasib',
  'lurus',
  'fokus',
  'pilih',
  'fakta',
  'pasir',
  'warga',
  'ketua',
  'temui',
  'segar',
  'pelan',
  'kejam',
  'napas',
  'badan',
  'patah',
  'nilai',
  'gemuk',
  'damai',
  'lulus',
  'basah',
  'tolak',
  'sejuk',
  'rumit',
  'sinar',
  'hakim',
  'putra',
  'alami',
  'bapak',
  'cuaca',
  'resmi',
  'ketat',
  'tekan',
  'tolol',
  'usaha',
  'parah',
  'dapur',
  'rindu',
  'ialah',
  'perut',
  'tebak',
  'pasar',
  'nyawa',
  'cewek',
  'nakal',
  'lebah',
  'lawan',
  'kolam',
  'buddy',
  'saksi',
  'ujian',
  'murah',
  'faham',
  'panik',
  'medis',
  'singa',
  'malah',
  'sabar',
  'bosan',
  'tulis',
  'kebun',
  'botol',
  'rokok',
  'mahal',
  'sopan',
  'cikgu',
]