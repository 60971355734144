export const VALIDGUESSES = [
'domba',
'gabuk',
'ahmar',
'buron',
'zuhur',
'kirim',
'keler',
'akmal',
'aulia',
'bajul',
'paman',
'soseh',
'sekam',
'suang',
'bilis',
'binal',
'cakna',
'hijab',
'abdas',
'kesip',
'dokok',
'tatai',
'moreh',
'mampu',
'dugas',
'batis',
'betet',
'jeran',
'mubah',
'petis',
'jambu',
'keria',
'togak',
'tunam',
'isbat',
'borci',
'dadih',
'batin',
'botor',
'darun',
'lente',
'zihar',
'leser',
'tepat',
'nanti',
'domah',
'zabib',
'bemak',
'anter',
'cekal',
'enzim',
'mudah',
'belas',
'dilah',
'kepam',
'popok',
'gelam',
'varia',
'sepet',
'pesai',
'fakta',
'leleh',
'tamam',
'poren',
'antep',
'sunai',
'fajar',
'jamah',
'kobok',
'suami',
'kenal',
'dilam',
'repak',
'takah',
'yakni',
'mikro',
'tojok',
'polek',
'repas',
'baran',
'dodol',
'impas',
'encal',
'kabil',
'kerup',
'atung',
'palka',
'bubuk',
'bruto',
'dawah',
'sampa',
'gamba',
'pepah',
'skrum',
'rabas',
'tebok',
'barat',
'hodoh',
'metro',
'telor',
'mulus',
'hakam',
'basal',
'lemon',
'encit',
'calat',
'sabut',
'taiko',
'lakab',
'taban',
'jerah',
'windu',
'cabau',
'jamik',
'katar',
'timur',
'ceber',
'panas',
'iqlab',
'kuala',
'tesak',
'suwir',
'aksep',
'gemas',
'darul',
'tedas',
'lebum',
'kalah',
'karil',
'umbra',
'umbun',
'wushu',
'cadak',
'pusat',
'bitis',
'kojoh',
'mahal',
'sayak',
'lokos',
'nadim',
'keweh',
'bedan',
'anyar',
'lotek',
'tepik',
'jetis',
'kasap',
'kelas',
'kernu',
'gagal',
'anbia',
'malar',
'demik',
'latur',
'aprit',
'jatik',
'boceh',
'sopan',
'dekri',
'banyu',
'langu',
'nuzul',
'taucu',
'puluh',
'ahram',
'purun',
'hujan',
'kaduk',
'baldu',
'desuk',
'lecer',
'lokus',
'pudat',
'fonem',
'leram',
'tekua',
'kambu',
'cepat',
'rulus',
'badwi',
'betul',
'ranah',
'kaldu',
'kasih',
'bereo',
'uzlah',
'bokoh',
'cagun',
'jarab',
'korea',
'jerak',
'joker',
'asfal',
'unjut',
'kelus',
'putut',
'cabuh',
'lokar',
'fatur',
'bedal',
'pegoh',
'besot',
'ancoa',
'bagak',
'sigak',
'jibun',
'labas',
'becok',
'bikar',
'samai',
'pinuh',
'waltz',
'poros',
'lepau',
'polos',
'pupal',
'jerai',
'bobol',
'bende',
'talar',
'lamai',
'hajah',
'gerik',
'embak',
'gabak',
'ceras',
'keran',
'lotah',
'kuyuk',
'omboh',
'besin',
'rewan',
'magma',
'almas',
'embat',
'belon',
'benum',
'farad',
'bilal',
'togel',
'punci',
'likir',
'lisan',
'jelai',
'bapak',
'waham',
'telah',
'tiang',
'perlu',
'dalal',
'cahar',
'berok',
'kakak',
'bayak',
'abaka',
'likat',
'bubus',
'burun',
'darma',
'rupal',
'vilus',
'lemau',
'zalad',
'memek',
'baken',
'gorok',
'rewel',
'kalau',
'kepik',
'peser',
'pacih',
'demut',
'botok',
'emper',
'lolen',
'jaloi',
'toyah',
'casis',
'ambil',
'ancak',
'baruh',
'polok',
'cukin',
'panik',
'entah',
'ganal',
'aiwan',
'cebek',
'tetap',
'binca',
'cuban',
'ahmak',
'jakal',
'bakap',
'sasak',
'mawas',
'alami',
'bokor',
'depun',
'celas',
'rodat',
'romba',
'kohol',
'baiah',
'tanin',
'benci',
'siluk',
'norma',
'buyar',
'komik',
'gelip',
'samah',
'tempe',
'rasul',
'cemeh',
'orang',
'patam',
'pereh',
'samba',
'gepuh',
'salip',
'bulan',
'macan',
'paris',
'arkib',
'lepan',
'koala',
'memar',
'amben',
'merak',
'lamur',
'sumin',
'apron',
'cabir',
'jalak',
'buaya',
'segah',
'suara',
'engku',
'anjar',
'batak',
'nonok',
'kaksa',
'bekak',
'lambu',
'keras',
'obral',
'tatar',
'melur',
'biaya',
'masih',
'temas',
'capil',
'akbar',
'praja',
'birih',
'sibur',
'kejal',
'kizib',
'tikar',
'cimni',
'irung',
'bodor',
'buhuk',
'daeng',
'sekip',
'acita',
'kamil',
'tenar',
'pumis',
'naqli',
'kawan',
'bagih',
'binar',
'jerau',
'nilam',
'barli',
'cekur',
'dapat',
'bidur',
'samar',
'kurva',
'bantu',
'birat',
'biadi',
'berot',
'beluh',
'beset',
'derup',
'lebai',
'ambul',
'lawar',
'kamar',
'tikas',
'gogol',
'tiara',
'damai',
'balui',
'rakap',
'aksen',
'tenda',
'bufet',
'tobak',
'korus',
'getil',
'ameba',
'botoh',
'kampa',
'cakah',
'porok',
'burut',
'jatuh',
'bales',
'borok',
'pugar',
'remai',
'tanih',
'tukas',
'pakai',
'ceduk',
'kenik',
'kodok',
'gemuk',
'bakit',
'ruang',
'becek',
'lidal',
'gigis',
'faham',
'gobar',
'lasah',
'godok',
'taram',
'cebeh',
'gipsi',
'desau',
'pecok',
'deris',
'delan',
'hasil',
'zahid',
'ustaz',
'angot',
'pensi',
'takma',
'camur',
'andan',
'kerbu',
'torek',
'renut',
'berat',
'awewe',
'tapin',
'rolet',
'gudeg',
'fabel',
'lucup',
'koloh',
'vivid',
'hakim',
'besen',
'gawal',
'piama',
'marga',
'kadim',
'bosor',
'reksa',
'kuria',
'cikgu',
'ginko',
'sekah',
'afiat',
'bakca',
'pakis',
'pesam',
'pitut',
'apnea',
'mambu',
'kaswi',
'kulan',
'kendi',
'ridan',
'tipus',
'baldi',
'balok',
'gulir',
'humor',
'banyo',
'fiber',
'saluk',
'galei',
'bareh',
'ketot',
'lasam',
'arbab',
'beber',
'lundi',
'bekah',
'danuh',
'kuyup',
'lupuk',
'leper',
'petan',
'bodok',
'uling',
'lafal',
'lipan',
'ifrit',
'kapan',
'lekis',
'bengu',
'kepai',
'lecok',
'ponco',
'sejak',
'lepap',
'lemuk',
'geruk',
'pakam',
'sodek',
'tuhan',
'rubin',
'pecal',
'bosan',
'santa',
'moyot',
'ghali',
'alkah',
'redan',
'oreng',
'lepik',
'kenit',
'demit',
'totok',
'tuaku',
'bapet',
'talas',
'sibuk',
'orkes',
'nyawa',
'kedua',
'geman',
'basis',
'garaj',
'tolap',
'rahat',
'sivil',
'bikir',
'jaksa',
'labut',
'infra',
'kalat',
'lonan',
'sikin',
'jalan',
'dalik',
'becar',
'kacam',
'cadai',
'dogel',
'linen',
'megun',
'sulah',
'suhun',
'jalia',
'pegat',
'abnus',
'mapan',
'gerek',
'jirai',
'banji',
'sokom',
'jakas',
'rajim',
'plaza',
'pilon',
'panir',
'adika',
'tasai',
'julap',
'kaloi',
'kamat',
'dunia',
'tambi',
'satar',
'girah',
'baghi',
'deham',
'empat',
'deguk',
'tenik',
'delik',
'karap',
'derak',
'tenat',
'katut',
'togan',
'geger',
'lukut',
'cucup',
'kekwa',
'lapar',
'pilus',
'remis',
'pilas',
'bogem',
'bokca',
'gisar',
'tulen',
'belum',
'kolam',
'oncom',
'sorek',
'colak',
'darai',
'ganja',
'dapur',
'borek',
'katir',
'caing',
'birai',
'ampek',
'zuhal',
'topes',
'biang',
'aplus',
'kelum',
'hapak',
'bukut',
'sikik',
'bacut',
'katib',
'nadir',
'gomok',
'ponok',
'jelik',
'patil',
'umrah',
'janur',
'angut',
'calui',
'tetal',
'banuk',
'ganti',
'koyan',
'silah',
'takur',
'halai',
'budak',
'genus',
'tirak',
'banal',
'ibung',
'gomba',
'ianya',
'kempu',
'lilis',
'nayam',
'skema',
'balur',
'tambo',
'mudin',
'datin',
'untut',
'terak',
'pukas',
'bekat',
'bonak',
'getis',
'sigun',
'teyan',
'didis',
'empap',
'pedak',
'safih',
'pakma',
'bardi',
'gayal',
'bodoh',
'gayuh',
'pupil',
'doyak',
'beksa',
'jitah',
'dodoi',
'bacin',
'gayam',
'beguk',
'altar',
'basil',
'karaf',
'teksi',
'tapir',
'lerum',
'asese',
'kebun',
'berit',
'putil',
'suloi',
'sumir',
'arbaa',
'gerit',
'temut',
'komet',
'rumit',
'gamis',
'ifrad',
'sitih',
'getek',
'luing',
'kisah',
'peroi',
'pilih',
'gubra',
'tabia',
'yakin',
'parih',
'merta',
'bulai',
'gemal',
'tulur',
'biksu',
'butal',
'jenis',
'kibir',
'pampa',
'jenut',
'telek',
'ghair',
'geruh',
'endah',
'ladan',
'arasy',
'bulus',
'kucai',
'tumus',
'ungti',
'cabur',
'gegat',
'kiras',
'turun',
'perut',
'enjet',
'embut',
'bagis',
'borat',
'lokap',
'bokek',
'depus',
'lanji',
'acung',
'gabah',
'citra',
'ayang',
'dosen',
'kemin',
'pepet',
'bahar',
'temin',
'bilur',
'kecur',
'ujana',
'safan',
'caduk',
'akhir',
'bebal',
'safar',
'cuaca',
'cerun',
'belam',
'dudut',
'depan',
'sakur',
'alibi',
'habis',
'puala',
'gapah',
'sigar',
'debap',
'endal',
'ummah',
'cakal',
'tapus',
'tirah',
'antan',
'bursa',
'ampuk',
'pisit',
'nguak',
'celam',
'gurah',
'batir',
'barak',
'takal',
'bahan',
'bukur',
'bakup',
'dinas',
'kerau',
'tahir',
'cekah',
'tanti',
'banit',
'sumpu',
'lesih',
'depot',
'lorat',
'legup',
'bokeh',
'sisal',
'sabur',
'ajami',
'abjad',
'gorek',
'selum',
'darah',
'degam',
'kromo',
'waktu',
'adres',
'begol',
'kepar',
'cenda',
'kemab',
'relau',
'harak',
'sebab',
'cakus',
'degum',
'kacau',
'pakau',
'jaing',
'picik',
'tarik',
'tetar',
'jabil',
'upiah',
'andun',
'garpu',
'layam',
'pohon',
'bukat',
'hepar',
'lenja',
'katod',
'anyur',
'angsa',
'peper',
'pasat',
'dekak',
'limpa',
'sivik',
'unguh',
'rejeh',
'lukah',
'dokoh',
'pikau',
'bagas',
'warid',
'bubur',
'dukat',
'babon',
'furuk',
'begak',
'leces',
'tauge',
'hamam',
'abras',
'purut',
'sidik',
'siwak',
'jeluk',
'balai',
'kekar',
'tidak',
'musuh',
'resmi',
'palar',
'ligas',
'sudut',
'sifir',
'hajim',
'giwah',
'gurur',
'lelah',
'batok',
'latuk',
'elang',
'barid',
'bujal',
'abtar',
'animo',
'gilan',
'gundi',
'komoi',
'bewok',
'sukun',
'sonor',
'aring',
'babut',
'lupat',
'ingin',
'petut',
'teman',
'kidal',
'kedul',
'kayap',
'kolar',
'dolar',
'benet',
'kebah',
'tojen',
'petas',
'abung',
'tolak',
'absah',
'rodan',
'baguk',
'terum',
'sayid',
'tiruk',
'gemap',
'tiris',
'julai',
'sains',
'galar',
'pipih',
'pompa',
'galuh',
'barau',
'sekin',
'baada',
'seban',
'tumit',
'hijau',
'canar',
'pelah',
'deban',
'lebun',
'takuh',
'kapri',
'amsal',
'salit',
'pecak',
'suasa',
'pasir',
'cempo',
'sabar',
'juala',
'anjir',
'langi',
'perdu',
'tarif',
'tairu',
'lucah',
'serai',
'napan',
'ambar',
'likas',
'lugas',
'badai',
'asbab',
'artik',
'berma',
'caung',
'lekir',
'beroi',
'berek',
'sujen',
'catuh',
'lepas',
'sajen',
'gubuk',
'paltu',
'ceker',
'kakek',
'kusir',
'larak',
'bihun',
'recup',
'xilem',
'bagor',
'gecar',
'keroi',
'paduk',
'tepus',
'bonda',
'segar',
'subal',
'buhul',
'dekih',
'pelas',
'lalah',
'kaman',
'ngiau',
'kajai',
'kulau',
'kasab',
'baguh',
'tekam',
'sando',
'taala',
'pekis',
'baras',
'larap',
'gunci',
'jirim',
'sebam',
'bogam',
'kepis',
'tisik',
'ultra',
'habib',
'calai',
'diksa',
'raksa',
'oskar',
'cogan',
'kusin',
'hantu',
'ekzos',
'baluk',
'sugar',
'pacat',
'lejar',
'lumas',
'bobok',
'kahwa',
'satwa',
'gabal',
'pasca',
'basuh',
'tampa',
'pelor',
'jirak',
'surat',
'eloan',
'gutik',
'merih',
'sebih',
'onyok',
'susuh',
'subam',
'kulim',
'harus',
'gorak',
'kadam',
'pepas',
'polip',
'pulai',
'biasa',
'derus',
'amali',
'jalil',
'luhur',
'redip',
'malik',
'ketat',
'bayur',
'uktab',
'teruk',
'pamah',
'pojok',
'bular',
'andar',
'calet',
'kojol',
'kalam',
'sanuk',
'bawak',
'kelak',
'lesir',
'bahau',
'toyol',
'wajah',
'serba',
'pepai',
'ijmal',
'gilau',
'mesti',
'dalca',
'didal',
'denak',
'pucik',
'suhuf',
'gomak',
'andas',
'kombo',
'konan',
'rumba',
'geluk',
'cadur',
'derek',
'syekh',
'tekah',
'johor',
'besek',
'zirah',
'gerau',
'tiket',
'sugul',
'tukam',
'lawas',
'pepes',
'bajau',
'leban',
'basah',
'panta',
'rajan',
'tahil',
'rimpi',
'konco',
'betik',
'paldu',
'gerha',
'bukan',
'lemba',
'larva',
'luyak',
'tewas',
'luluk',
'ialah',
'cucur',
'helat',
'rabik',
'reben',
'zapin',
'bakul',
'buram',
'tenor',
'wahah',
'guruh',
'mabur',
'podak',
'talun',
'unsat',
'magel',
'kolon',
'punya',
'kubik',
'desik',
'desut',
'kebus',
'afiks',
'rimbu',
'antap',
'haris',
'parsi',
'betat',
'iblis',
'tajau',
'datia',
'aurum',
'subuh',
'bagal',
'kirau',
'takau',
'celat',
'lipat',
'embal',
'bakai',
'boroi',
'nonol',
'kotor',
'dayah',
'sarwa',
'litak',
'yasin',
'puruh',
'wiski',
'pecai',
'patah',
'titar',
'ungar',
'kepur',
'parak',
'ragum',
'kebab',
'panca',
'dasau',
'kayan',
'batai',
'lesak',
'ashab',
'genit',
'qadak',
'delah',
'tapal',
'derut',
'bayar',
'nilau',
'kukup',
'ofset',
'piatu',
'gogah',
'gawar',
'butik',
'bafta',
'cakuk',
'gumal',
'saksi',
'tutup',
'boreh',
'letas',
'jidur',
'enjal',
'lumbu',
'patih',
'pitar',
'detup',
'pelir',
'vulva',
'rebek',
'kubis',
'kupoi',
'jaran',
'angun',
'bebas',
'milik',
'babad',
'culas',
'badar',
'ketan',
'tiram',
'kerek',
'lopek',
'lukih',
'mohon',
'estet',
'demah',
'debab',
'kenja',
'loleh',
'lebap',
'fakir',
'kedah',
'jelas',
'utara',
'suatu',
'derit',
'nyuar',
'umbai',
'jumpa',
'binau',
'gerut',
'ajnas',
'bindu',
'ancer',
'jersi',
'hudud',
'siang',
'halba',
'jidar',
'junta',
'kulir',
'ngelu',
'jidal',
'lidas',
'busat',
'pagai',
'donat',
'mimpi',
'ungam',
'cilik',
'padau',
'bazir',
'gadah',
'sabdu',
'ampel',
'detus',
'ceguk',
'tifus',
'arpus',
'absen',
'panen',
'beles',
'jahan',
'riket',
'angga',
'tipai',
'arnal',
'penai',
'bawat',
'jubli',
'golbi',
'lelai',
'macam',
'lahir',
'bunga',
'nisbi',
'amril',
'latuh',
'lejuh',
'bulir',
'beret',
'bacak',
'getas',
'tahun',
'pegal',
'kebit',
'cacil',
'tarak',
'komun',
'tujuh',
'godak',
'pukul',
'fokus',
'banda',
'heret',
'garau',
'andur',
'jumbo',
'ondoh',
'ikuti',
'kanal',
'yunda',
'gayat',
'endan',
'jabun',
'pasar',
'galai',
'putra',
'guano',
'jerih',
'samir',
'lobak',
'ramin',
'wasak',
'acang',
'yogia',
'sarut',
'sinki',
'tanau',
'bilar',
'celau',
'masuk',
'rokok',
'bejat',
'jepun',
'arena',
'lanau',
'titik',
'lerik',
'jalar',
'tutuk',
'tauci',
'ripuk',
'goyak',
'gubir',
'debuk',
'pusta',
'katih',
'duduk',
'bekil',
'kubal',
'polan',
'nomad',
'palat',
'asean',
'asnad',
'abrar',
'lulus',
'kejam',
'camca',
'empul',
'pahar',
'kuota',
'layuh',
'ketua',
'barus',
'giang',
'irasi',
'sunan',
'gagai',
'bawal',
'debam',
'hadat',
'tante',
'banta',
'bekuk',
'kebuk',
'bagul',
'nafar',
'gedek',
'tabib',
'taruk',
'kebel',
'kekot',
'cicak',
'ceceh',
'bakir',
'plato',
'jirat',
'nihil',
'ragus',
'bacuk',
'alias',
'dukan',
'malah',
'ampit',
'cemus',
'jarwa',
'gabas',
'sedih',
'bugar',
'garan',
'tauhu',
'kanan',
'tukus',
'samsu',
'tamar',
'jentu',
'sepoi',
'buraq',
'jawab',
'isnin',
'berik',
'ateis',
'lolok',
'tebak',
'randi',
'rehal',
'gubas',
'degar',
'tahar',
'sawab',
'kasur',
'allah',
'abuya',
'taris',
'terok',
'buruj',
'jajpa',
'meter',
'rizom',
'atase',
'fusta',
'bubar',
'delta',
'tenam',
'nenda',
'pahuk',
'hitam',
'wadih',
'biuku',
'virgo',
'hukum',
'cenge',
'pasah',
'indah',
'burat',
'beser',
'junub',
'punai',
'rumia',
'bilad',
'belia',
'gerus',
'bordu',
'gayuk',
'celar',
'nilur',
'isyak',
'samum',
'cemuk',
'ajung',
'tauke',
'petia',
'pulur',
'lewah',
'lupuh',
'solok',
'uskup',
'perus',
'china',
'tobji',
'putus',
'rawon',
'tepet',
'empis',
'lecap',
'sedah',
'totet',
'namun',
'retai',
'zabur',
'mabir',
'dokar',
'gulat',
'aswad',
'setan',
'petus',
'siber',
'nalar',
'lodak',
'audio',
'subak',
'panji',
'tonil',
'toyak',
'imsak',
'bakuh',
'balik',
'badan',
'gegak',
'uncak',
'kotak',
'izhar',
'ledor',
'dogma',
'popia',
'ghain',
'galir',
'cedak',
'asbut',
'takut',
'oknum',
'kasak',
'antat',
'panus',
'ilahi',
'kafal',
'lubuk',
'pisau',
'cedas',
'ferum',
'nilai',
'bebat',
'kunci',
'azali',
'watan',
'beiri',
'kudai',
'pudur',
'sudah',
'keman',
'cicah',
'birah',
'lompi',
'mandi',
'melut',
'ragin',
'balus',
'soyak',
'tenuk',
'ikrab',
'tikpi',
'egois',
'talam',
'babun',
'dunak',
'beruk',
'lurus',
'bujam',
'jabar',
'hanya',
'apkir',
'beleh',
'unjam',
'pugau',
'kabau',
'dajal',
'malim',
'yakut',
'jihat',
'lecur',
'pulau',
'lakri',
'rawah',
'rayon',
'dirus',
'aning',
'bakak',
'bekoi',
'rabbi',
'kesuk',
'segau',
'mesin',
'tabun',
'mabuk',
'lesus',
'unjap',
'apion',
'cdrom',
'tampu',
'murah',
'garis',
'bisai',
'fasik',
'dawai',
'kerki',
'warga',
'perak',
'bakar',
'lerap',
'baton',
'pegon',
'kekam',
'embus',
'insya',
'gancu',
'keong',
'arkan',
'jiwat',
'peran',
'tukil',
'locak',
'zuhud',
'syiah',
'uring',
'andal',
'kuang',
'salam',
'madya',
'advis',
'paluh',
'kasus',
'samas',
'koyam',
'raden',
'tubin',
'kabul',
'labak',
'ading',
'katai',
'marut',
'sitak',
'taran',
'dasun',
'akaun',
'benda',
'aling',
'kotot',
'lekar',
'gaguk',
'lebih',
'kabur',
'dodot',
'beran',
'boron',
'gitan',
'unduk',
'fikah',
'ludat',
'pekup',
'ijmak',
'tanur',
'legat',
'merah',
'gajak',
'beria',
'penuh',
'kaung',
'bacar',
'cenak',
'sadak',
'bayas',
'minum',
'napuh',
'erong',
'baron',
'megat',
'nikel',
'fetus',
'celis',
'qadim',
'arnab',
'janik',
'bukti',
'angur',
'derji',
'fomen',
'ganih',
'antih',
'rotor',
'cukup',
'pepek',
'tekis',
'seman',
'cadas',
'sandi',
'kembu',
'pusam',
'sanad',
'suruh',
'kepau',
'talan',
'bujuk',
'tuala',
'cekuh',
'lakum',
'terup',
'losen',
'anten',
'lunas',
'benar',
'rusam',
'jatah',
'elegi',
'benta',
'bolen',
'cacau',
'tutul',
'celuk',
'palsu',
'sitar',
'gerip',
'lesar',
'bawab',
'pakem',
'gabai',
'kredo',
'bendo',
'janji',
'jalad',
'jemah',
'lodoh',
'lesut',
'takup',
'todak',
'kudup',
'kasau',
'manda',
'anfas',
'kunca',
'pekan',
'tikus',
'kapal',
'libut',
'keroh',
'kepot',
'kudsi',
'cebak',
'gayau',
'tamuk',
'girap',
'pulan',
'buari',
'kasar',
'laser',
'bacul',
'ansar',
'basit',
'pokai',
'tawas',
'jagak',
'pelan',
'sulit',
'ihsan',
'rindu',
'dekit',
'pacis',
'capal',
'kemal',
'rango',
'boyas',
'halim',
'tablo',
'gayun',
'mobil',
'begap',
'calik',
'layas',
'magal',
'jalur',
'mejar',
'aging',
'kenas',
'cekit',
'fauna',
'siapa',
'nusus',
'legum',
'klise',
'regan',
'inggu',
'barua',
'sutuh',
'momok',
'asrar',
'beloh',
'derik',
'pupuh',
'firus',
'kusum',
'pintu',
'silap',
'timah',
'dekus',
'timun',
'akaid',
'bajik',
'salju',
'rafia',
'banar',
'iftar',
'geluh',
'batih',
'bopet',
'gegap',
'akwal',
'basau',
'kiani',
'caran',
'suguh',
'daras',
'lalap',
'penak',
'kasip',
'betuk',
'tawak',
'argus',
'tingi',
'danta',
'malai',
'mimik',
'sebun',
'bubun',
'nyolo',
'abese',
'kondo',
'cegar',
'suhad',
'totem',
'bidis',
'anduk',
'gerat',
'sedat',
'nakal',
'gurun',
'hukah',
'bayam',
'kandi',
'ambat',
'belir',
'capoi',
'buset',
'kasaj',
'retin',
'wahid',
'bogot',
'caram',
'galau',
'camar',
'cemak',
'tidur',
'palpa',
'calak',
'pulpa',
'wahai',
'lahad',
'perot',
'tepur',
'boria',
'hidro',
'undil',
'sinai',
'anyih',
'rutin',
'atawa',
'etnik',
'lepus',
'lorah',
'demap',
'perih',
'derun',
'atlas',
'ahlan',
'gerak',
'kuaci',
'pekur',
'tonik',
'iseng',
'liris',
'tepok',
'dupak',
'urung',
'tarum',
'riyak',
'gelup',
'unjun',
'lecek',
'lokan',
'qidam',
'bagus',
'teker',
'cegak',
'solak',
'dodos',
'gumai',
'hatta',
'kasti',
'perni',
'semut',
'lebam',
'barah',
'zamin',
'morat',
'pepat',
'rasau',
'catak',
'cemek',
'napal',
'palas',
'rudus',
'mulut',
'tokek',
'singa',
'jadam',
'tegal',
'tajin',
'hidup',
'murka',
'besar',
'gamut',
'bison',
'bauan',
'bocok',
'nambi',
'pedal',
'balan',
'beduk',
'mesiu',
'antul',
'batil',
'pacal',
'boksu',
'warna',
'mulur',
'pleno',
'pihak',
'durja',
'lasuh',
'alwah',
'taiso',
'ulser',
'angin',
'rimau',
'mulai',
'palma',
'denah',
'bakuk',
'sufal',
'putat',
'cebol',
'pejur',
'sagai',
'besok',
'mayor',
'jadah',
'lisol',
'moyok',
'rocah',
'lipas',
'tikal',
'koman',
'kuran',
'kesum',
'kasir',
'letus',
'datuk',
'modus',
'gilik',
'lensa',
'imago',
'lusuh',
'sakan',
'gotes',
'kepet',
'anang',
'nasib',
'dekut',
'gulut',
'ajrih',
'semua',
'suban',
'gumba',
'ingat',
'bunut',
'kemit',
'besit',
'pujut',
'telus',
'detar',
'bilak',
'encer',
'pecat',
'pesan',
'tusam',
'sekul',
'pajuh',
'nenes',
'desit',
'kadut',
'depap',
'umang',
'likuh',
'toyoh',
'kahin',
'lagam',
'dinar',
'cebet',
'lewat',
'gisal',
'minta',
'lampu',
'dedes',
'aerob',
'lanus',
'terus',
'lurik',
'gemam',
'butuh',
'lilan',
'aries',
'selup',
'cemat',
'benge',
'paria',
'imlek',
'sudip',
'bagur',
'batau',
'balet',
'sikah',
'gobek',
'dalam',
'koboi',
'robat',
'marah',
'sinar',
'palen',
'doger',
'papas',
'cawis',
'sugun',
'getir',
'tiung',
'buang',
'lepur',
'ketun',
'badal',
'ujang',
'putih',
'temui',
'sarik',
'gandi',
'tapih',
'gatra',
'bedel',
'tulat',
'sarau',
'sotor',
'macet',
'salah',
'tatal',
'anglo',
'lekeh',
'rangu',
'gurih',
'julir',
'manis',
'toaha',
'jamba',
'bolah',
'gigau',
'puruk',
'bolai',
'degur',
'usaha',
'mulas',
'giras',
'kalak',
'parau',
'nyana',
'piung',
'gasal',
'umbar',
'jarak',
'lorot',
'titir',
'riung',
'gepuk',
'afwah',
'rubai',
'batat',
'hindu',
'jamiz',
'cinta',
'tahan',
'budul',
'apati',
'besel',
'sedam',
'dungu',
'torak',
'tular',
'sakit',
'kebas',
'lolak',
'pacau',
'getak',
'pasti',
'camik',
'butut',
'sabuk',
'estar',
'fasal',
'kapis',
'pakuh',
'bakau',
'bumbu',
'bulur',
'bolor',
'bakal',
'empoh',
'debik',
'letuk',
'wajik',
'telas',
'blaus',
'kyoto',
'lebah',
'arung',
'dubuk',
'sosio',
'blurp',
'camuk',
'imlak',
'licau',
'lerak',
'litah',
'jahat',
'resak',
'dukuh',
'kekat',
'domol',
'jerun',
'bilit',
'joule',
'belom',
'ungah',
'antri',
'sikai',
'kahar',
'locok',
'desur',
'nenek',
'ayeng',
'campa',
'carak',
'kabit',
'dipan',
'hiruk',
'amboi',
'kawik',
'suari',
'tanji',
'fatom',
'sapta',
'debit',
'belus',
'pelih',
'sorai',
'wafak',
'parpu',
'pujur',
'papak',
'puing',
'buruk',
'carca',
'bokop',
'tuamu',
'baulu',
'lawah',
'jelit',
'celih',
'kicap',
'pepak',
'tubul',
'cagut',
'karet',
'kipai',
'latak',
'keban',
'gegau',
'abrak',
'dosej',
'linau',
'sojah',
'tubir',
'rabah',
'meski',
'walau',
'cabak',
'lawan',
'tanda',
'pipet',
'usali',
'betut',
'caruk',
'jujat',
'neram',
'diris',
'tunak',
'yajuj',
'magis',
'oasis',
'baret',
'lebak',
'pegar',
'becah',
'tukun',
'hidap',
'massa',
'relif',
'nalih',
'pitam',
'lelat',
'ujian',
'cabai',
'imbuh',
'lopes',
'endul',
'zigot',
'punti',
'robak',
'dayak',
'lecuh',
'pelog',
'nodai',
'ambet',
'patin',
'injap',
'baloi',
'tugar',
'sebut',
'padma',
'pelak',
'rancu',
'gapis',
'gandu',
'kerai',
'witir',
'lepat',
'gogok',
'lebuh',
'roset',
'pirai',
'boyan',
'sadah',
'kaboi',
'kupur',
'tanpa',
'becak',
'lekup',
'tiada',
'botak',
'abuan',
'tiras',
'antup',
'argon',
'tilan',
'becuk',
'bilut',
'loban',
'bocah',
'gibas',
'cebir',
'kuyuh',
'palau',
'anion',
'ridip',
'babuk',
'dekor',
'baung',
'lulai',
'lumer',
'gelap',
'qalam',
'lelan',
'hutan',
'cakak',
'udani',
'pawah',
'boyak',
'gerup',
'hasis',
'maung',
'gadis',
'pagan',
'banat',
'natal',
'desup',
'beton',
'latam',
'tentu',
'patar',
'celus',
'kilai',
'datoâ',
'barap',
'teper',
'saron',
'lesau',
'tenis',
'parah',
'radup',
'asang',
'tenak',
'sikak',
'khali',
'terau',
'wadah',
'novis',
'lanca',
'tukal',
'aspek',
'kuini',
'catan',
'bandu',
'genih',
'medan',
'kalbu',
'gebuk',
'cempa',
'gimik',
'juita',
'baksi',
'babal',
'ahkam',
'bunuh',
'becus',
'dekat',
'tasik',
'buduk',
'ajeng',
'bobek',
'benua',
'buret',
'bincu',
'camat',
'fikir',
'rosan',
'anyal',
'puyuh',
'balad',
'sahih',
'maddi',
'antah',
'nazir',
'bobos',
'madam',
'samin',
'kemap',
'ungku',
'butek',
'pecuk',
'lumat',
'bayuh',
'basuk',
'licik',
'lihat',
'final',
'kodak',
'danur',
'deruk',
'pipit',
'gapil',
'wisma',
'balon',
'taman',
'sihat',
'kenur',
'lagut',
'betak',
'hukka',
'sukma',
'topak',
'satir',
'harga',
'telap',
'gegai',
'kirip',
'ruwat',
'bisul',
'letik',
'sabtu',
'pokta',
'toman',
'bakam',
'dopis',
'pialu',
'anani',
'tolol',
'sepai',
'gensi',
'pupul',
'pacai',
'ondok',
'kasad',
'camau',
'embih',
'teral',
'tanju',
'terma',
'pamit',
'sabah',
'hebah',
'boleh',
'jadar',
'legih',
'sakai',
'bolos',
'kabak',
'telut',
'omega',
'tutor',
'lemek',
'kartu',
'injil',
'bicut',
'embuh',
'gawai',
'kisai',
'capar',
'tepeh',
'palai',
'tayum',
'pikuk',
'bubul',
'tabut',
'butul',
'purba',
'lisah',
'goyah',
'candi',
'gumuk',
'ludai',
'tekan',
'asfar',
'epsom',
'entek',
'pesek',
'payau',
'fenol',
'burka',
'dedap',
'labah',
'sokak',
'kulit',
'barik',
'tapuh',
'ungka',
'telur',
'pakuk',
'buana',
'bidak',
'macis',
'kebut',
'bukau',
'paron',
'tugal',
'kulak',
'dugal',
'kelut',
'nyata',
'harap',
'panja',
'boroh',
'murba',
'banjo',
'rejab',
'syala',
'hudai',
'layak',
'tanah',
'silia',
'aruda',
'rukam',
'oktaf',
'malam',
'nahak',
'beker',
'mayam',
'layur',
'nanar',
'israk',
'lepot',
'cekel',
'beram',
'tepas',
'penes',
'halir',
'cakap',
'kumus',
'keret',
'legap',
'tupai',
'mamak',
'lundu',
'medal',
'cekar',
'bambu',
'randu',
'hamis',
'kudat',
'pegas',
'cacap',
'salih',
'untuk',
'ropak',
'kader',
'nazak',
'sulat',
'hiput',
'tunku',
'alung',
'along',
'geran',
'afrit',
'begal',
'buran',
'andil',
'muluk',
'garut',
'kijil',
'celum',
'dengu',
'incer',
'ruwin',
'wilis',
'kolak',
'botol',
'antui',
'gagak',
'semar',
'kayun',
'laden',
'adpis',
'denim',
'boket',
'celek',
'arked',
'bawah',
'sahan',
'temak',
'sabil',
'belin',
'luluh',
'ambah',
'jejap',
'sonet',
'macuk',
'bagau',
'alusi',
'bawel',
'amaun',
'jelur',
'disko',
'teori',
'kerja',
'berup',
'limas',
'denai',
'nilon',
'virus',
'gajus',
'letap',
'koyok',
'debak',
'hafiz',
'siguh',
'kilar',
'abrit',
'cadik',
'arbei',
'sinyo',
'asing',
'tinak',
'cabuk',
'lerah',
'pamor',
'ambon',
'agong',
'abses',
'kedap',
'aksam',
'ritma',
'gebar',
'salur',
'sedua',
'topek',
'kaper',
'utama',
'belau',
'pergi',
'kiung',
'loloh',
'tabii',
'waduk',
'sipai',
'cemer',
'jebat',
'debas',
'lagan',
'lekuh',
'sejuk',
'rusih',
'makan',
'berko',
'pekin',
'piang',
'lelak',
'surin',
'radin',
'polio',
'demis',
'kaing',
'sakar',
'salbi',
'bahwa',
'kecar',
'pekap',
'biara',
'silam',
'iaitu',
'tanya',
'lukat',
'ngian',
'begar',
'ritau',
'gawat',
'karim',
'anaam',
'sagur',
'kecil',
'rumah',
'capek',
'hebat',
'cekih',
'syiar',
'masya',
'budek',
'dikau',
'kolah',
'bebeh',
'saban',
'harbi',
'jebuh',
'bonus',
'ubang',
'fasis',
'bakik',
'puyun',
'tulis',
'capah',
'sifar',
'april',
'wasit',
'biram',
'sekoi',
'asbak',
'satah',
'gilas',
'betas',
'belar',
'jazam',
'india',
'cabit',
'bomoh',
'lumai',
'angon',
'baduk',
'lakak',
'kejat',
'ketum',
'antar',
'lumus',
'lumus',
]
